body {
  font-family: 'Lato', sans-serif;
  background-color: #ECF0F1;
  color: #2C3E50;
  margin: 0;
  padding: 0;
}

header {
  background-color: #2C3E50;
  color: white;
  padding: 20px;
  text-align: center;
}

header img {
  border-radius: 10px;
  width: 300px;
}

header a {
  color: #1ABC9C;
  text-decoration: none;
}

header a:hover {
  text-decoration: underline;
}

section {
  margin: 20px auto;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1, h2 {
  font-family: 'Open Sans', sans-serif;
}

.skills, .experience, .education, .projects, .certifications {
  margin-bottom: 20px;
}

footer {
  background-color: #2C3E50;
  color: white;
  text-align: center;
  padding: 10px;
}

footer a {
  color: #1ABC9C;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
